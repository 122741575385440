<template>
  <huboo-page>
    <div class="logoContainer">
      <v-img>
        <huboo-logo></huboo-logo>
      </v-img>
    </div>
    <v-card max-width="600px" class="mx-auto" flat>
      <huboo-form
        id="loginForm"
        :fields="fields"
        hide-dividers
        lazy-validation
        :loading="loading"
        show-submit
        @submit="submit"
        :title="$t('auth.logIn') | title"
      ></huboo-form>
    </v-card>
  </huboo-page>
</template>

<script>
import { isEmailAddress, isRequired } from '@/utilities/validations'

export default {
  name: 'Login',
  data: () => ({}),
  computed: {
    emailField() {
      return {
        autocomplete: 'on',
        errorMessages: this.validationError('email'),
        label: this.$t('common.email'),
        name: 'email',
        rules: [isEmailAddress, isRequired],
        type: 'email',
        validateOnBlur: true,
      }
    },
    fields() {
      return [this.emailField, this.passwordField]
    },
    loading() {
      return [
        { method: 'POST', url: '/user/login' },
        { method: 'POST', url: '/user/refresh-token' },
        { method: 'GET', url: '/auth-user' },
      ].reduce((a, { method, url }) => {
        return a || this.$store.getters['core/apiEndpointIsLoading']({ method, url })
      }, false)
    },
    passwordField() {
      return {
        autocomplete: 'on',
        // TODO: reactivate when API fixed (currently sends back a 400 error for wrong password)
        // errorMessages: this.validationError('password'),
        errorMessages: this.passwordWorkaroundShouldDeleteThisASAP,
        label: this.$t('common.password'),
        name: 'password',
        rules: [isRequired],
        type: 'password',
        validateOnBlur: true,
      }
    },
    passwordWorkaroundShouldDeleteThisASAP() {
      // TODO: remove passwordWorkaroundShouldDeleteThisASAP once API response corrected
      if (this.$store.getters['core/getErrors']?.[400]?.error === 'invalid_grant')
        return this.$t('auth.emailAndPasswordDoNotMatchOurRecords')
      else return null
    },
  },
  methods: {
    submit(e) {
      this.$store.dispatch('core/logIn', e?.values)
    },
    validationError(e) {
      return this.$store.getters['core/validationError'](e)
    },
  },
}
</script>

<style lang="scss" scoped>
.logoContainer {
  max-width: 300px;
  margin: auto;
}
</style>
