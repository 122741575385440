<template>
  <huboo-page>
    <h1>Invoicing</h1>
  </huboo-page>
</template>

<script>
export default {
  name: 'Invoicing',
  data: () => ({}),
}
</script>

<style lang="scss" scoped></style>
